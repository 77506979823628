// Om Shri Satnam Sakshi//
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./view/screen/pages/Home";
import Menubar from "./view/screen/Menubar";
import Footer from "./view/screen/Footer";
import About from "./view/screen/pages/About";
import Contact from "./view/screen/pages/Contact";
import Services from "./view/screen/pages/Services/Services";
import Cyber from "./view/screen/pages/Services/Cyber/Cyber";
import Data from "./view/screen/pages/Services/Data/Data";
import Hardware from "./view/screen/pages/Services/Hardware/Hardware";
import Network from "./view/screen/pages/Services/Network/Network";
import Software from "./view/screen/pages/Services/Software/Software";
import Cloud from "./view/screen/pages/Services/Cloud/Cloud";
import "./App.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <>
       <body>
  <div>
    
   
 <Router>
 <Menubar />
  <Routes>
    <Route path="/" element={<Home />}/>
    <Route path="/About" element={<About/>}/>

    <Route path="/Services" element={<Services />}/>
    <Route path="/Contact" element={<Contact />}/>
   
    <Route path="/Cyber" element={<Cyber />}/>
    <Route path="/Data" element={<Data />}/>
    <Route path="/Hardware" element={<Hardware />}/>
    <Route path="/Software" element={<Software />}/>
    <Route path="/Network" element={<Network />}/>
    <Route path="/Cloud" element={<Cloud />}/>
    
  </Routes>
  <Footer />
  </Router>
  </div>

  <a href="https://wa.me/7229915333" className="whatsapp-contact" target="_blank">
        <FontAwesomeIcon icon={faWhatsapp} className="whaticon" />
        {/* <span>Contact Us</span> */}
      </a>

  <a href="#" class="top" >
        <FontAwesomeIcon icon={faArrowUp}  /> 
      </a>
      
    
    </body>
  </>
  );
}

export default App;
