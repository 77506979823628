import "./Software.scss";
import AOS from "aos";
import { useEffect,useState } from "react";
function Software(){
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return(
        
<>
     
           <div className="about_text" > 
                        <img className="aboutimg" src= './softbg.jpg' alt="xyz" />
                        <div className="upperdiv"></div>
                        <div class="about"  data-aos="fade-up">
                         <h1>Software Solutions</h1>
                        </div>
                        </div>


                        <div className="parallax-container6">
{/* <img src="./cyber.jpg" alt="Small Image" /> */}
<div className="parallax-image-right6">
  {/* <img src="./office2.jpg" alt="Small Image" />  */}
</div>
<div className="text-container6" data-aos="fade-up">
  <div className="about-us_home6">
    <h2>WHAT IS SOFTWARE SOLUTION?</h2>
    <p>
   
A software solution is a tailored program or application designed to address specific challenges or meet particular needs. It provides a digital answer to a problem, streamlining processes, enhancing efficiency, and improving overall performance within a given context.
    </p>
   
  </div>

</div>
</div>
 </>
    )
}
export default Software;