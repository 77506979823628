import "./Cards.scss";
import {Button,Card,Container,Carousel,Form,} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useRef, useState, useEffect } from "react";
import "animate.css/animate.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUsers,faTasks,faClock,faSmile,} from "@fortawesome/free-solid-svg-icons";
import {MDBCardBody,MDBIcon} from "mdb-react-ui-kit";
import "swiper/css";
import "swiper/css/pagination";
import { useForm } from "react-hook-form";
import { Alert } from "react-bootstrap";




const Cardd = ({ imgSrc, authorName, snippet, key }) => {
  return (
    <figure className="snip1232" key={key}>
      <div className="author">
        <img src={imgSrc} alt={authorName} />
        <h5>{authorName}</h5>
      </div>
      <blockquote>{snippet}</blockquote>
    </figure>
  );
};

function Cards() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  //contact
  const form = useRef();
  const { register, handleSubmit, formState, reset } = useForm();
  const { isSubmitting } = formState;
  const [showAlert, setShowAlert] = useState(false);

  const handleAlertAndReset = () => {
    setShowAlert(true); // Show success alert
    reset(); // Reset form
  };
const Cardd = ({ imgSrc, authorName, snippet, key }) => {
  return (
    <figure className="snip1232" key={key}>
      <div className="author">
        <img src={imgSrc} alt={authorName} />
        <h5>{authorName}</h5>
      </div>
      <blockquote>{snippet}</blockquote>
    </figure>
  );
};



const cardsData = [
  {
    imgSrc: './t1.png',
    authorName: 'Expert Counsel',
    snippet: "Our commitment to providing personalized guidance from seasoned industry leaders."
   
  },
  {
    imgSrc: './t2.png',
    authorName: 'Affordable Services',
    snippet: " Experience the assurance of quality paired with affordability in our services, ensuring value without compromise."  
  },
  {
    imgSrc: './t3.png',
    authorName: 'Skilled Engineers',
    snippet: " Rely on our certified and skilled engineers for precision and expertise, ensuring top-notch solutions tailored to your needs."
  },
  {
    imgSrc: './t4.png',
    authorName: 'Complete Solutions ',
    snippet: "Elevate operations with our integrated solutions for efficient and innovative success."
  },
  {
    imgSrc: './t5.png',
    authorName: 'Focus on Client ROI',
    snippet: "We prioritize client ROI, ensuring consistent focus on delivering value and measurable returns."
  },
  {
    imgSrc: './t6.png',
    authorName: 'Business Alliances',
    snippet: " Forge strategic business alliances, unlocking growth and opportunities for mutual success."
  },
  // Add more cards as needed
];







  return (
    <>

    
      {/* About US Code */}
      <div className="parallax-container" style={{backgroundImage: "url('./tyty.jpg')"}}>
       
       <div className="parallax-image-right">
      
       </div>
       <div className="text-container" data-aos="fade-up">
         <div className="about-us_home">
           <h2>Who We Are</h2>
           <p>
           3Handshake Innovation Pvt. Ltd. is a dynamic company delivering excellence in IT services from quadrennial years by three experienced entrepreneurs. Committed to transparency, feasibility, scalability, and trustworthiness, our modern technology solutions empower enterprises with world-class services in Cyber Security, Network Solution, Cloud Solutions, CCTV Surveillance.
           </p>
         </div>
       </div>
     </div>

      <div className="Achi_text" data-aos="fade-up">
        <h2>
          <a>
          {"Our "}
            <span className="onlyc">C</span>
            <span>ommitment</span>
          </a>
          <p>Empowering Excellence</p>
        </h2>

        
      </div>


        <div className="cardd-container" data-aos="fade-up" >
          {cardsData.map((cardd, index) => (
            <Cardd
              imgSrc={cardd.imgSrc}
              authorName={cardd.authorName}
              snippet={cardd.snippet}
              key={index}
              data-aos={cardd.aos}
            />
          ))}
        </div>




      <section id="timeline">
    <div className="Achi_text" data-aos="zoom-in">
       
        <h2> <a href="/Services" id="style-2" data-replace="Expertise">
        {"Our "}
        <span className="onlyc">E</span>
          <span>xpertise</span></a><p>We Can Help You With...</p></h2>
      </div>
	
	<div class="demo-card-wrapper" >
		<div class="demo-card demo-card--step1" data-aos="fade-right">
			<div class="head">
				<div class="number-box" >
					<span>01</span>
				</div>
				<h2 ><a href="/Cyber">Cyber Security</a></h2>
			</div>
			<div class="body">
				<p>Firewall/UTM , Endpoint Security, DDoS Protection.</p>
				<img src="./cyber.jpeg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step2"  data-aos="fade-left">
			<div class="head">
				<div class="number-box">
					<span>02</span>
				</div>
				<h2><a href="/Data">Data Security</a></h2>
			</div>
			<div class="body">
				<p>Data Leakage Prevention,Data Backup or Cloud Storage,Encryption,Email Security,Access Contral,VPN Solution.</p>
				<img src="cyber2.jpeg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step3" data-aos="fade-right">
			<div class="head">
				<div class="number-box">
					<span>03</span>
				</div>
				<h2><a href="/Network">Network & Security</a> </h2>
			</div>
			<div class="body">
				<p>Designing Active-Passive Network, Wireless Solutions, CCTV Surveillance,Biometric.</p>
				<img src="net.jpg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step4" data-aos="fade-left">
			<div class="head">
				<div class="number-box">
					<span>04</span>
				</div>
				<h2><a href="/Cloud">Cloud Solutions</a></h2>
			</div>
			<div class="body">
				<p>Cloud Manged Services,Cloud Hosting,Web Services,Web Hosting,Web-Server.</p>
				<img src="cloud.jpg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step5" data-aos="fade-right">
			<div class="head">
				<div class="number-box">
					<span>05</span>
				</div>
				<h2> <a href="/Software">Software Solutions</a></h2>
			</div>
			<div class="body">
				<p>Office 365 Email,Software Licensing,Employee Monitoring Tools,Payroll Software,Antivirus.</p>
				<img src="software.jpg" alt="Graphic"/>
			</div>
		</div>

        <div class="demo-card demo-card--step6" data-aos="fade-left">
			<div class="head">
				<div class="number-box">
					<span>06</span>
				</div>
				<h2> <a href="/Hardware">Hardware Solutions</a></h2>
			</div>
			<div class="body">
				<p>Desktop,Laptop,Server,Gaming PC,Storage,Computer Peripheral.</p>
				<img src="hardware.avif" alt="Graphic"/>
			</div>
		</div>
    
	</div>
</section> 


{/* Our Assurance */}
      <div className="Achi_text" data-aos="zoom-in">
        <h2 className="onlyt">
         
          <a>
          {"Our "}
            <span >A</span>
            <span>ssurance</span>
          </a>
          <p>Your Confidence, Our Priority</p>
        </h2>
      </div>

      <div className="achievement">
        <div className="achievements-container">
          <div className="achievement-card">
            <FontAwesomeIcon icon={faUsers} className="achievement-icon" />
            <h3>Clients Served</h3>
            <h1>1000+</h1>
          </div>
          <div className="achievement-card">
            <FontAwesomeIcon icon={faTasks} className="achievement-icon" />
            <h3>Projects Completed</h3>
            <h1> 103+</h1>
          </div>
          <div className="achievement-card">
            <FontAwesomeIcon icon={faClock} className="achievement-icon" />
            <h3>Experience Hours</h3>
            <h1> 11k+</h1>
          </div>
          <div className="achievement-card">
            <FontAwesomeIcon icon={faSmile} className="achievement-icon" />
            <h3>Client Satisfaction</h3>
            <h1> 98.67%</h1>
          </div>
        </div>
      </div>

      <div className="Achi_text" data-aos="zoom-in">
        <h2>
          
          <a>
          {"Our "}

            <span className="onlyc">C</span>
            <span>lients</span>
          </a>
          <p>Our Proud Journey</p>
        </h2>
      </div>

      <Carousel className="Carousel" fade>
        <Carousel.Item interval={1000} >
          <Card.Img src="./cl1.png" />
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <Card.Img src="./cl22.png" />
        </Carousel.Item>
      </Carousel>

      <div className="Achi_text" data-aos="zoom-in">
        <h2>
        
          <a>
          {"Our "}

            <span className="onlycc">E</span>
            <span>xperiences</span>
          </a>
          <p>Our Clients Feedback</p>
        </h2>
      </div>

      <div className="App">
      <div className="card-container">
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
            <MDBCardBody className="py-4 mt-2" >
             
              <h5 className="font-weight-bold">Mr. Shashank Mathur</h5>
              <h6 className="font-weight-bold my-3">Sterling Urban Co-operative Bank Ltd.</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                We trust 3HS for their unwavering commitment to security. Their solutions ensure the confidentiality and integrity of our financial data.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
         </div>



                  <div className="card-back">
                  <MDBCardBody className="py-4 mt-2" >
              
              <h5 className="font-weight-bold">Mr. Sachin Bhargav</h5>
              <h6 className="font-weight-bold my-3">Process & Machines Automation Systems</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3Handshake Innovation Pvt. Ltd. has been our trusted IT support for years they delivered cost-effective solutions without compromising quality. They consistently provide quick, effective, and reliable IT solutions.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
                  
                  </div>
          </div>
        </div>



        <div className="card">
          <div className="card-inner">
            <div className="card-front">
            <MDBCardBody className="py-4 mt-2" >
            
              <h5 className="font-weight-bold">Mr. Mohit Bahl</h5>
              <h6 className="font-weight-bold my-3">Cogent 360 Solutions Pvt. Ltd.</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3HS delivers excellence! The services of this company are very fast and the quick response time and a complete suite of  all IT solutions.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            </div>
            <div className="card-back">
            <MDBCardBody className="py-4 mt-2" >
              
              <h5 className="font-weight-bold">Mr. Sachin Sharma</h5>
              <h6 className="font-weight-bold my-3">Danish Pvt. Ltd. </h6>
              
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3Handshake Innovation Pvt. Ltd. 5-star expertise in
   camera, biometric, hardware, software, networking, and cloud
 solutions, benefiting Danish Pvt. Ltd.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            </div>
          </div>
        </div>


        <div className="card">
          <div className="card-inner">
            <div className="card-front">
            <MDBCardBody className="py-4 mt-2" >
             
              <h5 className="font-weight-bold">Mr. Arpit Vijayvargiya</h5>
              <h6 className="font-weight-bold my-3">Art India</h6>
              
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                3Handshake Innovation Pvt. Ltd. consistently provide quick, effective, and reliable IT solutions makes them our favorite service provider. 

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            
            </div>
            <div className="card-back">
            <MDBCardBody className="py-4 mt-2" >
            
              <h5 className="font-weight-bold">Mr. Prateek Chaubey</h5>
              <h6 className="font-weight-bold my-3">iGlobe Solutions</h6>
             
              <p className="mb-2">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                We are very satisfied with the services of 3HS. They have well trained IT staff who provide us with IT and Cyber solutions. 3HS exceeds our expectations.

                  <MDBIcon fas icon="quote-right" className="pe-2"  />
              </p>
            </MDBCardBody>
            </div>
          </div>
        </div>
      </div>
    </div>


{/* Contact */}
<Container className="contact-page-sec">
      <div className="contactpage" method="post">
      <div className="Achi_text" data-aos="zoom-in">
        <h2>
        
          <a href="/contact">
          {"Contact "}

            <span className="onlycc">U</span>
            <span>s</span>
          </a>
          <p>Get In Touch</p>
        </h2>
      </div>

      {showAlert && (
              <Alert
                className="mt-4"
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                Response recorded, Thank You!
              </Alert>
            )}



            <Form ref={form} onSubmit={handleSubmit(handleAlertAndReset)}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="user_name"
                  placeholder="Enter your name"
                  {...register("name", { required: "Name is required" })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="user_email"
                  placeholder="Enter email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </Form.Group>

              <Form.Group controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={5}
                  placeholder="Enter your message"
                  {...register("message", {
                    required: "Message is required",
                  })}
                />
              </Form.Group>

              <Button
                className="cbtn"
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Container>
    </>
  );
}

export default Cards;
