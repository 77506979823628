import "./Cloud.scss";
import AOS from "aos";
import { useEffect,useState } from "react";
import { motion } from 'framer-motion';
function Cloud(){
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return(
        <>

          
             <div className="about_text" > 
                                <img className="aboutimg" src= './cloudbg.jpg' alt="xyz" />
                                <div className="upperdiv"></div>
                                <div class="about"  data-aos="fade-up">
                                 <h1>Cloud Solutions</h1>
                                </div>
                                </div>

             


<div className="parallax-container5">
{/* <img src="./cyber.jpg" alt="Small Image" /> */}
<div className="parallax-image-right5">
  {/* <img src="./office2.jpg" alt="Small Image" />  */}
</div>
<div className="text-container5" data-aos="fade-up">
  <div className="about-us_home5">
    <h2>WHAT IS CLOUD BACKUP</h2>
    <p>
    Cloud backup is a data protection method where digital files and information are stored remotely on servers accessed via the internet. It works by regularly copying and transferring data from a user's device to a secure cloud storage service, providing a reliable and off-site backup solution to prevent data loss in case of device failure or other emergencies.
    </p>
   
  </div>

</div>
</div>

                                </>
    )
}
export default Cloud;