import "./Network.scss";
import AOS from "aos";
import { useEffect,useState } from "react";
function Network(){
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return(
        
     
    <>
           <div className="about_text" > 
                        <img className="aboutimg" src= './netbg.jpg' alt="xyz" />
                        <div className="upperdiv"></div>
                        <div class="about"  data-aos="fade-up">
                         <h1>Network & Security</h1>
                        </div>
                        </div>



<div className="parallax-container4">
{/* <img src="./cyber.jpg" alt="Small Image" /> */}
<div className="parallax-image-right4">
  {/* <img src="./office2.jpg" alt="Small Image" />  */}
</div>
<div className="text-container4" data-aos="fade-up">
  <div className="about-us_home4">
    <h2>NETWORK SECURITY FOR BUSINESSES </h2>
    <p>
    For businesses and consumers, network security is vital. Businesses employ firewalls, encryption, and intrusion detection. Consumers should prioritize strong passwords, regular software updates, and cautious online behavior to mitigate cyber threats.
    </p>
   
  </div>

</div>
</div>
</>    
    )
}
export default Network;