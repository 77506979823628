import "./Cyber.scss";
import AOS from "aos";
import { useEffect,useState } from "react";
import { motion } from 'framer-motion';
function Cyber(){
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return(
        <>
        <div className="about_text" > 
        <img className="aboutimg" src= './cyback.jpg' alt="xyz" />
        <div className="upperdiv"></div>
        <div class="about"  data-aos="fade-up">
         <h1>Cyber Security
 
         </h1>
         
        </div>
        </div>

        <div className="parallax-container2">
        {/* <img src="./cyber.jpg" alt="Small Image" /> */}
        <div className="parallax-image-right2">
          {/* <img src="./office2.jpg" alt="Small Image" />  */}
        </div>
        <div className="text-container2" data-aos="fade-up">
          <div className="about-us_home2">
            <h2>WHAT IS CYBER SECURITY?</h2>
            <p>
            Digital protection, or information technology security, safeguards electronic systems, devices, and data from malicious attacks. It includes network, application, and data security, crucial in contexts ranging from business to mobile computing. 
            </p>
           
          </div>
     
      </div>
      </div>


        </>
    )
}
export default Cyber;