
import "./Home.scss";
import Cards from "../Cards";
function Home()
{
  
 return(
        <>
         {/* Video background */}
         <div className="video-background">
        <img src='./bg.avif' />
        <div className="content">
          <h1>
            Technology Made Simple
          </h1>
        </div>
      </div>
     <Cards />
    </>
    )
}
    export default Home;