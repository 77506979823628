import "./Hardware.scss";
import AOS from "aos";
import { useEffect,useState } from "react";
function Hardware(){
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return(
        
    <>
           
          <div className="about_text" > 
                <img className="aboutimg" src= './hardbg.avif' alt="xyz" />
                <div className="upperdiv"></div>
                <div class="about"  data-aos="fade-up">
                 <h1>Hardware Solutions</h1>
                </div>
                </div>


<div className="parallax-container7">
{/* <img src="./cyber.jpg" alt="Small Image" /> */}
<div className="parallax-image-right7">
  {/* <img src="./office2.jpg" alt="Small Image" />  */}
</div>
<div className="text-container7" data-aos="fade-up">
  <div className="about-us_home7">
    <h2>WHAT IS HARDWARE SOLUTIONS?</h2>
    <p>
    A hardware solution refers to a physical device or component designed to address a specific problem or perform a particular function. Examples include computer hardware, routers, and security tokens, providing tangible tools to meet various technological needs.
    </p>
   
  </div>

</div>
</div>
</>
    )
}
export default Hardware;