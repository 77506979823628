import React from 'react';
import "./Footer.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
function footer(){
  const handleSubmit = () => {
    // Perform any action you want when the button is clicked
    console.log('Email submitted');
  };
    return(
    <>
  <footer className="footer-section">
            <div className="container">
                <div className="footer-cta pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <i className="fas fa-map-marker-alt" style={{marginTop:"-12%"}}></i>
                                <div className="cta-text">
                                    <h4 >Find us</h4>
                                    <span><a href="https://maps.app.goo.gl/Wj77t52tKJ1ARmbN6">3Handshake Innovation Pvt. Ltd. <br/>36-A, 1<sup>st</sup>Floor ,Near Shubh Hospital, <br/>Vidhyut Nagar A, Ajmer Road, <br/>Jaipur 302021, Rajasthan</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-30" >
                            <div className="single-cta1" >
                              <i className="fas fa-phone flip-horizontal"></i>

                                <div className="cta-text" >
                                    <h4>Call us</h4>
                                    <span><a href="tel:+91 722991 5333" >Enquiry: +91 722991 5333</a></span><br/>
                                    <span><a href="tel:+91 637771 7361" >Support: +91 637771 7361 </a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta" >
                                <i className="far fa-envelope-open"></i>
                                <div className="cta-text">
                                    <h4>Mail us</h4>
                                    <span><a href="mailto:enquiry@3handshake.com">enquiry@3handshake.com</a></span><br/>
                                    <span><a href="mailto:support@3handshake.com">support@3handshake.com</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <a href="/"><img src="logo.png" className="img-fluid" alt="logo" /></a>
                                    <p>Technology Made Simple...</p>
                                </div>
                                <div className="footer-text">
                                   
                                </div>
                                <div className="footer-social-icon">
                                    <span>Follow us</span>
                                    <a href='https://www.facebook.com/3handshake?mibextid=ZbWKwL' class="social-icon social-icon--facebook" target="_blank" rel="noopener noreferrer">
  <i className="fab fa-facebook-f facebook-bg"></i>
  <span class="tooltip">Facebook</span>
</a>

<a href='https://instagram.com/3handshake?igshid=OGQ5ZDc2ODk2ZA==' target="_blank" rel="noopener noreferrer">
  <i className="fab fa-instagram instagram-bg"></i>
  <span class="tooltip">Instagram</span>
</a>

<a href='https://www.linkedin.com/company/3handshake-innovation-pvt-ltd1/' target="_blank" rel="noopener noreferrer">
  <i className="fab fa-linkedin linkedin-bg"></i>
  <span class="tooltip">LinkedIn</span>
</a>

<a href='https://wa.me/7229915333' target="_blank" rel="noopener noreferrer">
  <i className="fab fa-whatsapp whatsapp-bg"></i>
  <span class="tooltip">WhatsApp</span>
</a>





                                </div>
                            </div>
                        </div>
            
                        <div className="links col-xl-2 col-lg-2 col-md-4 mb-15">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                <li><a href="/">Home</a></li>
                                  <li><a href="/Services">Our Services</a></li>
                                  <li><a href="/About">About us</a></li>
                                  <li><a href="/Contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
{/*         
                        <div className="col-xl-2 col-lg-2 col-md-4 mb-15">
                            <div className="footer-services">
                                <div className="footer-widget-heading">
                                    <h3>Our Services</h3>
                                </div>
                                <ul>
                                <li><a href="/">Cyber Security</a></li>
                                  <li><a href="/Services">Data Security</a></li>
                                  <li><a href="/About">Network & Security</a></li>
                                  <li><a href="/Contact">Cloud Solution</a></li>
                                  <li><a href="/Contact">Software Solution</a></li>
                                  <li><a href="/Contact">Hardware Solution</a></li>

                                </ul>
                            </div>
                         </div> */}
                  
                        <div className="map col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Map</h3>
                                </div>

                                <div className="footer-text mb-25">
                                  <iframe
                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3416.909562159279!2d75.74901249357525!3d26.896724659343697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db7c3e6aea415%3A0xa10d75b00b27c123!2s3HandShake%20Innovation%20Pvt.%20Ltd.!5e1!3m2!1sen!2sus!4v1699252098662!5m2!1sen!2sus"
                                      width="100%"
                                      height="250"
                                      frameBorder="0"
                                      style={{ border: 0 }}
                                      allowFullScreen
                                      title="Company Location"
                                  ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                            <div className="copyright-text">
                                <p>Copyright &copy; 2023 <a href="/">3Handshake Innovation Pvt. Ltd.</a> All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                            <div className="footer-menu">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="#">Terms</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Policy</a></li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
      </>
    )
}
export default footer;