import "./Services.scss";
import AOS from "aos";
import { useEffect } from "react";
function Services(){

    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);


      
    return(
        <>
<div className="about_text" > 

<img className="aboutimg" src= './services.jpg'alt="xyz" />
<div className="upperdiv"></div>
<div class="servicecontent"  data-aos="fade-up">
 <h1>Services</h1>
</div>   
</div>

<section class="home">
      <div class="description">
        <h1 class="title"> 
          <span class="gradient-text">Elevating Trust</span>  in a, Digital Universe
        </h1>
        <p class="paragraph">
        At 3HS, we specialize in navigating the digital frontier, offering robust solutions in cyber security, data security, network & Security, cloud Solution, software and hardware solutions. Our expert team ensures seamless integration for fortified digital resilience.
        </p>
       
      </div>

      <div class="users-color-container">
       
        <img
          class="item"
		//   src="3hslogo.png"

        
          alt="" />
       
		<img
          class="item"
		  src="1.png"

        
          alt="" />




    <img
          class="item"
		//   src="3hslogo.png"

        
          alt="" />
       
		<img
          class="item"
		  src="2.png"

        
          alt="" />
       

       


		<img
          class="item"
		   src="3.png"

        
          alt="" />
      
		<img
          class="item"
		  // src="net.jpg"

        
          alt="" />
		     <img
          class="item"
		  src="4.png"
        alt="" />   
         
          <img
          class="item"
		  // src="cloud.jpg"

        
          alt="" />



<img
          class="item"
		   //src="cloud.jpg"

        
          alt="" /> 
          
          <img
          class="item"
		   src="5.png"
alt="" /> 


        <img
          class="item"
		      //src="cloud.jpg"
          alt="" /> 
          
          
          <img
          class="item"
          
		   src="6.png"
          alt="" />
      </div>
    </section>

    <section id="timeline">
    <div className="Achi_text" data-aos="zoom-in">
       
        <h2>Our <a href="#" id="style-2" data-replace="Expertise">
        <span className="onlyc">E</span>
          <span>xpertise</span></a><p>We Can Help You With...</p></h2>
      </div>
	
	<div class="demo-card-wrapper" >
		<div class="demo-card demo-card--step1" data-aos="fade-right">
			<div class="head">
				<div class="number-box" >
					<span>01</span>
				</div>
				<h2> Cyber Security</h2>
			</div>
			<div class="body">
				<p>Firewall/UTM , Endpoint Security, DDoS Protection.</p>
				<img src="./cyber.jpeg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step2"  data-aos="fade-left">
			<div class="head">
				<div class="number-box">
					<span>02</span>
				</div>
				<h2> Data Security</h2>
			</div>
			<div class="body">
				<p>Data Leakage Prevention,Data Backup or Cloud Storage,Encryption,Email Security,Access Contral,VPN Solution.</p>
				<img src="cyber2.jpeg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step3" data-aos="fade-right">
			<div class="head">
				<div class="number-box">
					<span>03</span>
				</div>
				<h2>Network & Security </h2>
			</div>
			<div class="body">
				<p>Designing Active-Passive Network, Wireless Solutions, CCTV Surveillance,Biometric.</p>
				<img src="net.jpg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step4" data-aos="fade-left">
			<div class="head">
				<div class="number-box">
					<span>04</span>
				</div>
				<h2>Cloud Solutions</h2>
			</div>
			<div class="body">
				<p>Cloud Manged Services,Cloud Hosting,Web Services,Web Hosting,Web-Server.</p>
				<img src="cloud.jpg" alt="Graphic"/>
			</div>
		</div>

		<div class="demo-card demo-card--step5" data-aos="fade-right">
			<div class="head">
				<div class="number-box">
					<span>05</span>
				</div>
				<h2> Software Solutions</h2>
			</div>
			<div class="body">
				<p>Office 365 Email,Software Licensing,Employee Monitoring Tools,Payroll Software,Antivirus.</p>
				<img src="software.jpg" alt="Graphic"/>
			</div>
		</div>

        <div class="demo-card demo-card--step6" data-aos="fade-left">
			<div class="head">
				<div class="number-box">
					<span>06</span>
				</div>
				<h2> Hardware Solutions</h2>
			</div>
			<div class="body">
				<p>Desktop,Laptop,Server,Gaming PC,Storage,Computer Peripheral.</p>
				<img src="hardware.avif" alt="Graphic"/>
			</div>
		</div>
    
	</div>
</section> 
    


<div class="main">
      <div className="Achi_text" data-aos="zoom-in">
       
       <h2>We <a href="#" id="style-2" data-replace="Expertise">
       <span className="onlyc">D</span>
         <span>eal with</span></a><p>We Can Also Help You With...</p></h2>
     </div>
  <ul class="carrds">
    <li class="cards_item">
      <div class="carrd" data-aos="fade-right">
            <div className="card_image"><img src="/END.jpg"/></div>
              <div className="card_content">
                <h2 className="card_title">END-POINT SECURITY</h2>
                <p className="card_text">Endpoint security for desktops, laptops and mobile devices.</p>
              </div>
      </div>
    </li>
    <li className="cards_item">
      <div className="carrd" data-aos="fade">
        <div className="card_image"><img src="/dlp.png"/></div>
        <div className="card_content">
          <h2 className="card_title">DLP & ENCRYPTION SOLUTION</h2>
          <p className="card_text">DLP safeguards sensitive information from unauthorized access.</p>
        
        </div>
      </div>
    </li>
    <li className="cards_item">
      <div className="carrd" data-aos="fade-left">
        <div className="card_image"><img src="/email.jpg"/></div>
        <div className="card_content">
          <h2 className="card_title">EMAIL SOLUTIONS</h2>
          <p className="card_text">Email solution for retaining valuable corporate information.</p>
         
        </div>
      </div>
    </li>
    <li className="cards_item">
      <div className="carrd" data-aos="fade-right">
        <div className="card_image"><img src="/desk.jpg"/></div>
        <div className="card_content">
          <h2 className="card_title">DESKTOP & LAPTOP SOLUTIONS</h2>
          <p className="card_text">Provide all solution related to desktops and laptops.</p>
          
        </div>
      </div>
    </li>
    <li className="cards_item">
      <div className="carrd" data-aos="fade">
        <div className="card_image"><img src="/cctv.jpg"/></div>
        <div className="card_content">
          <h2 className="card_title">CCTV SURVEILLANCE </h2>
          <p className="card_text">CCTV camera installation and management.</p>
        
        </div>
      </div>
    </li>
    <li class="cards_item">
      <div class="carrd" data-aos="fade-left">
        <div class="card_image"><img src="mobile.avif"/></div>
        <div class="card_content">
          <h2 class="card_title">MOBILE DEVICE MANAGEMENT</h2>
          <p class="card_text">MDM for monitor, and manage end-user mobile devices.</p>
          
        </div>
      </div>
    </li>


    <li className="cards_item">
      <div className="carrd" data-aos="fade-right">
                <div className="card_image"><img src="av.jpg"/></div>
              <div className="card_content">
                <h2 className="card_title">AUDIO & VIDEO CONFERENCE</h2>
                <p className="card_text">Solution provides for Audio & video conferencing.</p>
              
              </div>
        </div>
    </li>
    <li className="cards_item">
      <div className="carrd" data-aos="fade">
                <div className="card_image"><img src="RR.jpg"/></div>
              <div className="card_content">
                <h2 className="card_title">ROUTER-SWITCHES SOLUTIONS</h2>
                <p className="card_text">Routing network configration & switches solution.</p>
              
              </div>
        </div>
    </li>
    <li className="cards_item">
      <div className="carrd" data-aos="fade-left">
                <div className="card_image"><img src="wifii.avif"/></div>
              <div className="card_content">
                <h2 className="card_title">WIFI SOLUTION
                </h2>
                <p className="card_text"> Wireless Solutions Designed to Support Data.</p>
              
              </div>
        </div>
    </li>

  </ul>
</div>

   
      </>
    )
}
export default Services;