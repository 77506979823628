import "./Data.scss";
import AOS from "aos";
import { useEffect } from "react";
function Data(){
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return(
        <>
        <div className="about_text" > 
        <img className="aboutimg" src= './databg.jpg' alt="xyz" />
        <div className="upperdiv"></div>
        <div class="about"  data-aos="fade-up">
         <h1>Data Security</h1>
        </div>
        </div>


<div className="parallax-container3">
{/* <img src="./cyber.jpg" alt="Small Image" /> */}
<div className="parallax-image-right3">
  {/* <img src="./office2.jpg" alt="Small Image" />  */}
</div>
<div className="text-container3" data-aos="fade-up">
  <div className="about-us_home3">
    <h2>WHY DATA SECURITY?</h2>
    <p>
    Data security is crucial to protect sensitive information from unauthorized access, 
    breaches, or loss. It safeguards privacy, maintains trust, and ensures the integrity of digital assets, preventing potential financial and reputational damage.
    </p>
   
  </div>

</div>
</div>
</>
    )
}
export default Data;