import {useEffect,useState} from 'react';
import { Navbar, Container, Nav, NavDropdown, Dropdown,ButtonGroup,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Menubar.scss';
// import Home from './pages/Home';
// import About from './pages/About';
// import Services from './pages/Services';
function Menubar() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const winScroll = document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setScrollPercentage(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };
  return (
    <>
 
 <header>

  
  


      <Navbar expand="lg" bg="black" variant="dark" className='Navbar'  >
      <Container>
        <Navbar.Brand href="/">
          <img src="logo3hs.png" alt="Company Logo" />
        </Navbar.Brand>
        <Navbar.Toggle  className='toggle' aria-controls="basic-navbar-nav"  />

        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ml-auto">
          <li class="nav-item">
          <a className="nav-link" href="/" style={{color:'white'}}>Home</a>
        </li>
        
        <li class="nav-item">
          <a class="nav-link " aria-current="page" href="/About" style={{color:'white'}}>About Us</a>
        </li>

        <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle"  href="/Services" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color:'white'}}>
      Services
    </a>
    <ul class="dropdown-menu">
    <li class="nav-itemm dropend">
        <a class="nav-link dropdown-toggle" href="/Cyber" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Cyber Security
        </a>
        <ul class="dropdown-menu1">
          <li><a class="dropdown-item" href="/Cyber">Firewall/UTM</a></li>
          <li><a class="dropdown-item" href="/Cyber">Endpoint Security</a></li>
          <li><a class="dropdown-item" href="/Cyber">DDoS Protection</a></li>
        </ul>
      </li>

      
      <li class="nav-item dropend">
        <a class="nav-link dropdown-toggle" href="/Data" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Data Security
        </a>
        <ul class="dropdown-menu1">
          <li><a class="dropdown-item" href="/Data">Data Leakage Prevention</a></li>
          <li><a class="dropdown-item" href="/Data">Data Backup or CLoud Storage</a></li>
          <li><a class="dropdown-item" href="/Data">Encryption</a></li>
          <li><a class="dropdown-item" href="/Data">Email Security</a></li>
          <li><a class="dropdown-item" href="/Data">VPN Solution</a></li>

        </ul>
      </li>

      <li class="nav-item dropend">
        <a class="nav-link dropdown-toggle" href="/Network" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Network & Security
        </a>
        <ul class="dropdown-menu1">
          <li><a class="dropdown-item" href="/Network">Designing Active-Passive Network</a></li>
          <li><a class="dropdown-item" href="/Network">Wireless Solution</a></li>
          <li><a class="dropdown-item" href="/Network">CCTV Surveillance</a></li>
          <li><a class="dropdown-item" href="/Network">Biometric</a></li>
        </ul>
      </li>

      <li class="nav-item dropend">
        <a class="nav-link dropdown-toggle" href="/Cloud" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Cloud Solutions
        </a>
        <ul class="dropdown-menu1">
          <li><a class="dropdown-item" href="/Cloud">Cloud Managed Services</a></li>
          <li><a class="dropdown-item" href="/Cloud">Cloud Hosting</a></li>
          <li><a class="dropdown-item" href="/Cloud">Web Service/Hosting</a></li>
          <li><a class="dropdown-item" href="/Cloud">Web-Server Protection</a></li>
          <li><a class="dropdown-item" href="/Cloud">Email Solution</a></li>
        </ul>
      </li>
      

      <li class="nav-item dropend">
        <a class="nav-link dropdown-toggle" href="/Software" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Software Solutions
        </a>
        <ul class="dropdown-menu1">
          <li><a class="dropdown-item" href="/Software">Office 365 Email</a></li>
          <li><a class="dropdown-item" href="/Software">Software Licensing</a></li>
          <li><a class="dropdown-item" href="/Software">Employee Monitoring Tools</a></li>
          <li><a class="dropdown-item" href="/Software">Payroll Software</a></li>
          <li><a class="dropdown-item" href="/Software">Antivirus</a></li>
        
        </ul>
      </li>

      <li class="nav-item dropend">
        <a class="nav-link dropdown-toggle" href="/Hardware" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Hardware Solutions
        </a>
        <ul class="dropdown-menu1">
          <li><a class="dropdown-item" href="/Hardware">Desktop</a></li>
          <li><a class="dropdown-item" href="/Hardware">Laptop</a></li>
          <li><a class="dropdown-item" href="/Hardware">Server</a></li>
          <li><a class="dropdown-item" href="/Hardware">Gaming PC</a></li>
          <li><a class="dropdown-item" href="/Hardware">Storage Computer </a>
          </li>
        </ul>
      </li>
    </ul>
</li>
<li class="nav-item">
          <a class="nav-link " aria-current="page" href="/Contact" style={{color:'white'}}>Contact Us</a>
        </li>
      
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
     <div className="scroll-line" style={{ width: `${scrollPercentage}%` }}></div>
    </header>



    </>
  );
}

export default Menubar;
